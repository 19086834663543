import * as t from '../types';
import axios from './axios-instance-config';
import { API_ROOT } from '../../config';
import DataHelper from '../../config/DataHelper';
import { headers } from '../../config/HeaderHelper';
import {
  GET_VIRTUAL_URL,
  GET_ABOUT_LOCALITY,
} from '../../config/EndpointHelper';

export const getVirtualUrl = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'virtualUrlReducer' },
      payload: true,
    });

    const apiResponse = await axios.post(
      API_ROOT + GET_VIRTUAL_URL,
      DataHelper(props),
      {
        headers: headers,
      }
    );

    dispatch({
      type: t.GET_VIRTUAL_URL,
      meta: { reducer: 'virtualUrlReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'virtualUrlReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'virtualUrlReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'virtualUrlReducer' },
      payload: error,
    });
  }
};

export const getAboutLocality = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'aboutLocalityReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_ABOUT_LOCALITY, {
      params: props,
    });

    dispatch({
      type: t.GET_ABOUT_LOCALITY,
      meta: { reducer: 'aboutLocalityReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'aboutLocalityReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'aboutLocalityReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'aboutLocalityReducer' },
      payload: error,
    });
  }
};

export const enquiryFormSubmit = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'enquiryFormReducer' },
      payload: true,
    });

    const apiResponse = await axios.post(
      ENQUIRY_FORM_SUBMIT_API,
      DataHelper(props)
    );

    dispatch({
      type: t.ENQUIRY_FORM_SUBMIT,
      meta: { reducer: 'enquiryFormReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'enquiryFormReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'enquiryFormReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'enquiryFormReducer' },
      payload: error,
    });
  }
};
