import { useState, useEffect } from 'react';

const useUserIp = () => {
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const ip = window.localStorage.getItem('clientIpAddress');
    if (ip === 'undefined' || !ip) {
      setIpAddress('1.1.1.1');
    } else {
      setIpAddress(ip);
    }
  }, []);

  return [ipAddress, setIpAddress];
};

export default useUserIp;
