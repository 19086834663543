import React from 'react';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import ClassNames from 'classnames';

export default function HBTextField(props) {
  const classes = useStyles();
  const {
    name,
    id,
    label,
    isDisabled,
    varient,
    className,
    size,
    value,
    onChange,
    error,
    placeholder,
    helperText,
    style,
    ...otherParams
  } = props;
  return (
    <>
      <div className={ClassNames([classes.textInput, className])}>
        <TextField
          name={name}
          type="text"
          id={id}
          label={label}
          disabled={isDisabled}
          variant={varient || 'outlined'}
          style={style}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error}
          placeholder={placeholder}
          size={size || 'small'}
          autoComplete="on"
          {...otherParams}
        />
      </div>
    </>
  );
}
