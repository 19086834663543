import * as t from '../types';
import axios from './axios-instance-config';
import { API_ROOT } from '../../config';
import DataHelper from '../../config/DataHelper';
import { headers } from '../../config/HeaderHelper';
import {
  GET_PROPERTY_HEADER_API,
  GET_DEVELOPER_INFO_API,
  POST_CONTACTED_SEEN_PROPERTY_API,
} from '../../config/EndpointHelper';

export const getPropertyHeader = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'propertyHeaderReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(
      `${API_ROOT}${GET_PROPERTY_HEADER_API}`,
      { params: props }
      // DataHelper(props),
      // {
      //   headers: headers,
      // }
    );
    dispatch({
      type: t.GET_PROPERTY_HEADER,
      meta: { reducer: 'propertyHeaderReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'propertyHeaderReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'propertyHeaderReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'propertyHeaderReducer' },
      payload: error,
    });
  }
};

export const contactedSeenPropertyApicall = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'cotactedseenpropertyReducer' },
      payload: true,
    });

    const apiResponse = await axios.post(
      API_ROOT + POST_CONTACTED_SEEN_PROPERTY_API,
      DataHelper(props),
      {
        headers: headers,
      }
    );
    dispatch({
      type: t.POST_CONTACTED_SEEN_PROPERTY,
      meta: { reducer: 'cotactedseenpropertyReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'cotactedseenpropertyReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'cotactedseenpropertyReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'cotactedseenpropertyReducer' },
      payload: error,
    });
  }
};

export const getBuilderDetails = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'builderDetailsReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_DEVELOPER_INFO_API, {
      params: props,
    });

    dispatch({
      type: t.GET_DEVELOPER_INFO,
      meta: { reducer: 'builderDetailsReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'builderDetailsReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'builderDetailsReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'builderDetailsReducer' },
      payload: error,
    });
  }
};
