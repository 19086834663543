import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    '.Mui-focused': {
      backgroundColor: '#000',
    },

    input: {
      '& :selected': {
        backgroundColor: '#fff!important',
      },
    },
  },

  textInput: {
    margin: '0 0 8px 0',
    backgroundColor: '#fff',

    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },

    '& .MuiInputLabel-filled': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#333333',
      top: '5px',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#333333',
      top: '5px',
    },
    '& .MuiFilledInput-inputMarginDense': {
      backgroundColor: '#fff',
      border: '1px solid #99A2AC',
      borderRadius: '7px',
      paddingTop: '36px',
      paddingBottom: '10px',
    },
    '& .MuiInputBase-root': {
      fontSize: '18px',
      fontWeight: '700',
      color: '#171717',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },

  textInput1: {
    margin: '0 0 8px 0',
    backgroundColor: '#fff',

    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },

    '& .MuiInputLabel-filled': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#333333',
      top: '5px',
    },

    '& .MuiInputLabel-filled::placeholder': {
      color: '#171717 !important',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#333333',
      top: '5px',
    },
    '& .MuiFilledInput-inputMarginDense': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #D3E0ED',
      paddingBottom: '10px',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: '0px',
      fontSize: '16px',
      fontWeight: '700',
    },

    '& .MuiFilledInput-inputMarginDense::placeholder': {
      fontSize: '16px',
      color: '#171717 !important',
    },
    '& .MuiInputBase-root': {
      fontSize: '18px',
      fontWeight: '700',
      color: '#171717',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0px',
      fontSize: '13px',
    },
  },
}));
