import Input from '@material-ui/core/Input';
import useUtmParams from '../../../../Hooks/useUtmParams';

function UtmParamsInput() {
  const params = useUtmParams();
  return (
    <>
      {params.gclid && (
        <Input type="hidden" name="gclid" id="gclid" value={params.gclid} />
      )}
      {params.utm_source && (
        <Input
          type="hidden"
          name="utm_source"
          id="utm_source"
          value={params.utm_source}
        />
      )}
      {params.utm_medium && (
        <Input
          type="hidden"
          name="utm_medium"
          id="utm_medium"
          value={params.utm_medium}
        />
      )}
      {params.utm_campaign && (
        <Input
          type="hidden"
          name="utm_campaign"
          id="utm_campaign"
          value={params.utm_campaign}
        />
      )}
      {params.utm_term && (
        <Input
          type="hidden"
          name="utm_term"
          id="utm_term"
          value={params.utm_term}
        />
      )}
      {params.fbclid && (
        <Input type="hidden" name="fbclid" id="fbclid" value={params.fbclid} />
      )}
    </>
  );
}

export default UtmParamsInput;
