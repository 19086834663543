import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ClassNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#B9B9B9',
      borderRadius: '12px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    padding: '0px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize',
    },
    '& .MuiButtonBase-root.Mui-selected': {
      padding: '20px 0px',
      backgroundColor: '#0B56B2',
      color: '#fff',
      boxShadow: 'none',
      borderRadius: '12px!important',
      border: 'none!important',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '27px',
      letterSpacing: '0px',
      height: '69px',
      fontFamily: 'Open Sans, sans-serif!important',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '27px',
        letterSpacing: '0px',
        height: '54px',
      },
    },

    '& .MuiBox': {
      padding: '0px !important',
    },
    '& .MuiTab-root': {
      padding: '20px 0px',
      fontFamily: 'Open Sans, sans-serif!important',
      opacity: '1',
      backgroundColor: '#F3F3FF',
      borderRadius: '12px',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0px',
      height: '69px',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '27px',
        letterSpacing: '0px',
        height: '54px',
      },
    },
    // '& .MuiButtonBase-root': {
    //   backgroundColor: '#F3F3FF',
    //   color: '#171717',
    //   border: 0,
    //   fontSize: '14px',
    //   fontWeight: 'Open Sans',
    // },
  },

  // commenting this for performance
  // shortroot: {
  //   //backgroundColor: '#FFF',
  //   borderRadius: '12px',
  //   // border: "1px solid #e3edf7",
  //   borderTopLeftRadius: '10px',
  //   borderTopRightRadius: '10px',
  //   padding: '0px',
  //   width: '100%',
  //   '& .MuiTabs-indicator': {
  //     display: 'none',
  //   },
  //   '& .MuiTab-wrapper': {
  //     textTransform: 'capitalize',
  //     fontFamily: 'Open Sans',
  //   },
  //   '& .MuiButtonBase-root.Mui-selected': {
  //     backgroundColor: 'transparent !Important',
  //     color: '#464646',
  //     boxShadow: 'none',
  //     borderBottom: '4px Solid #2E72BB',

  //     '& .MuiTab-textColorInherit.Mui-selected': {
  //       opacity: 'none !Important',
  //     },
  //   },
  //   '& .MuiButtonBase-root.Mui-selected:first-child': {
  //     borderTopLeftRadius: '12px',
  //   },
  //   '& .MuiButtonBase-root.Mui-selected:last-child': {
  //     borderTopRightRadius: '12px',
  //   },
  //   '& .MuiBox': {
  //     padding: '0px !important',
  //   },
  // },
  commTabs: {
    borderRadius: '12px',
    color: '#171717',
    font: 'normal normal bold 14px/19px Open Sans',
    opacity: 1,
    fontWeight: 700,
    left: '0',
    width: '100%',
    height: '69px',
    marginBottom: '7px',

    '& .MuiTab-root': {
      minWidth: 'calc(49% - 2px)!important',
      width: 'calc(49% - 2px)!important',
      border: '1px solid #D3E0ED',
      [theme.breakpoints.down('sm')]: {
        '& .MuiTabs-scroller': {
          margin: '5px',
        },
        width: 'calc(50% - 4px)!important',
      },
    },
    '& .MuiButtonBase-root:first-child': {
      borderTopLeftRadius: '12px!important',
      marginRight: '9px',
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        padding: '0px 11px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0px',
      },
      '& .MuiTab-root': {
        height: '54px',
        width: 'calc(50% - 4px)',
        // width: '100%',
        // minWidth: '170px!important',
        // maxWidth: '170px!important',
        // margin: '0px auto',
      },
      position: 'relative',
      top: '12px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },

    [theme.breakpoints.down('ssm')]: {
      '& .MuiTabs-scroller': {
        margin: '5px',
      },
      '& .MuiTab-root': {
        // height: '54px',
        // width: '100%',
        // minWidth: '160px!important',
        // maxWidth: '160px!important',
        // margin: '0px auto',
      },
      position: 'relative',
      top: '12px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
  },
}));

function areaProps(index) {
  return {
    id: `simple-tab-${index}`,
    ['data-cy']: 'tab-btn-' + index,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      data-cy={'tab-btn-' + index}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CommonTabs = props => {
  const { list, root_class, active_index } = props;
  const classes = useStyles();
  const [tab_value, setTabValue] = React.useState(
    active_index ? active_index : 0
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className={ClassNames([classes.root, root_class])}>
        <Tabs
          className={classes.commTabs}
          value={tab_value}
          onChange={handleChange}
          aria-label="tabs"
          style={{}}>
          {list &&
            list.map((data, index) => {
              const { label } = data;
              return <Tab key={index} label={label} {...areaProps(index)} />;
            })}
        </Tabs>
        {list &&
          list.map((data, index) => {
            const { value } = data;
            return (
              <TabPanel value={tab_value} key={index} index={index}>
                {value}
              </TabPanel>
            );
          })}
      </div>
    </>
  );
};

export default CommonTabs;
