import { useState } from 'react';
import CommonHeaderText from '../../../packages/common-header-text';
import { Button } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import _ from 'lodash';
import { API_ROOT } from '../../../../config';
import axios from 'axios';
import { ENQUIRY_FORM_SUBMIT_API } from '../../../../config/EndpointHelper';
import DataHelper from '../../../../config/DataHelper';
import { headers } from '../../../../config/HeaderHelper';
import CircularProgress from '@mui/material/CircularProgress';
import useUtmParams from '../../../../Hooks/useUtmParams';
import useUserIp from '../../../../components/packages/use-user-ip';
import { storeItem } from '../../../../config/LocalStorage';
import styles from './leadotp.module.scss';
import { contactedSeenPropertyApicall } from '../../../../redux/actions/propertyDetails';
import { connect } from 'react-redux';
import useUserInfo from '@/components/packages/use-user-info';
import useAccessToken from '@/components/packages/use-access-token';

const LeadOptForm = props => {
  const {
    Country,
    rawCountry = false,
    values,
    url,
    property_id,
    enquiredFor,
    parentCallback,
    header,
    setOpenPopup,
    setMyToken,
  } = props;
  const [otp, setOTP] = useState('');
  const utmParams = useUtmParams();
  const [form_loading, setFormLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [ip_address] = useUserIp();
  const [, setUserInfo] = useUserInfo();
  const [, setAccessToken] = useAccessToken();

  const verifyBtn = () => {
    if (otp !== '') {
      handleSubmit();
    } else {
      setErrorMsg('Please enter the OTP ');
    }
  };

  const contactedSeenPropertyApiCall = (
    module_type_contacted,
    user_contacted,
    property_id_contacted
  ) => {
    props.contactedSeenPropertyApicall({
      module_type: module_type_contacted,
      user_id: user_contacted?.id,
      property_id: property_id_contacted,
      category: 'contacted',
    });
  };

  // Resend Otp
  const ResendOTP = async () => {
    const country = rawCountry
      ? Country
      : Country.split('|') || ['91', 'India'];
    const countryName = rawCountry ? country.countryName : country[0];
    const countryCode = rawCountry ? country.countryCodeLabel : country[1];
    _.merge(values, { url: url });
    _.merge(values, { type: 0 });
    property_id && _.merge(values, { project_id: property_id });
    _.merge(values, { country: countryName });
    _.merge(values, { country_code: countryCode });
    _.merge(values, { gclid: utmParams.gclid ?? '' });
    _.merge(values, { fbclid: utmParams.fbclid ?? '' });
    _.merge(values, {
      utm_source: utmParams.utm_source ?? '',
    });
    _.merge(values, {
      utm_medium: utmParams.utm_medium ?? '',
    });
    _.merge(values, {
      utm_campaign: utmParams.utm_campaign ?? '',
    });
    _.merge(values, {
      utm_term: utmParams.utm_term ?? '',
    });
    _.merge(values, { ip: ip_address });
    _.merge(values, { enquiredfor: enquiredFor });
    _.merge(values, { otp: '' });
    setFormLoading(true);
    setSuccess('');
    setErrorMsg('');

    await axios
      .post(API_ROOT + ENQUIRY_FORM_SUBMIT_API, DataHelper(values), {
        headers: headers,
      })
      .then(async response => {
        if (response.status == 200) {
          setSuccess(response.data.message);
          parentCallback(response.data.message);
        } else if (response.status == 202) {
          setErrorMsg(response.data.email);
        }
        setFormLoading(false);
      })
      .catch(error => {
        setFormLoading(false);
        setErrorMsg('invalid OTP');
        if (error.status == 400) {
          setFormLoading(false);
          setErrorMsg('invalid OTP');
        }
      });
  };
  const handleSubmit = () => {
    let country = rawCountry ? Country : Country.split('|') || ['91', 'India'];
    const countryName = rawCountry ? country.countryName : country[0];
    const countryCode = rawCountry ? country.countryCodeLabel : country[1];
    _.merge(values, { url: url });
    _.merge(values, { type: 0 });
    property_id && _.merge(values, { project_id: property_id });
    _.merge(values, { country: countryName });
    _.merge(values, { country_code: countryCode });
    _.merge(values, { gclid: utmParams.gclid ?? '' });
    _.merge(values, { fbclid: utmParams.fbclid ?? '' });
    _.merge(values, { utm_source: utmParams.utm_source ?? '' });
    _.merge(values, { utm_medium: utmParams.utm_medium ?? '' });
    _.merge(values, { utm_campaign: utmParams.utm_campaign ?? '' });
    _.merge(values, { utm_term: utmParams.utm_term ?? '' });
    _.merge(values, { ip: ip_address });
    _.merge(values, { enquiredfor: enquiredFor });
    _.merge(values, { otp: otp });
    setFormLoading(true);
    setSuccess('');
    setErrorMsg('');

    return new Promise((resolve, reject) => {
      axios
        .post(API_ROOT + ENQUIRY_FORM_SUBMIT_API, DataHelper(values), {
          headers: headers,
        })
        .then(response => {
          if (response.status === 200) {
            setSuccess(response.data.message);
            parentCallback(response.data.message);
            setUserInfo(response?.data?.user || {});
            setAccessToken(response?.data?.access_token || '');
            storeItem('expires_at', response?.data?.expires_at || '');
            contactedSeenPropertyApiCall(
              values.module_type ?? '',
              response?.data?.user || {},
              property_id
            );
            setMyToken();
            resolve(response);
          } else if (response.status === 202) {
            setErrorMsg(response.data.email);
            setErrorMsg(response.data.message);
            reject(response);
          } else {
            reject(new Error(`Unexpected response status: ${response.status}`));
          }
        })
        .catch(error => {
          setFormLoading(false);
          setErrorMsg('invalid OTP');
          if (error.response && error.response.status === 400) {
            setErrorMsg('invalid OTP');
          }
          reject(error);
        });
    });
  };

  return (
    <div className={styles.root}>
      <div
        style={{
          font: 'normal normal bold 20px/27px Open Sans',
          color: '#171717',
          marginBottom: '10px',
        }}>
        {header ? header : 'Talk to our expert'}
      </div>
      <CommonHeaderText
        text={'Enter OTP to verify'}
        variant="h6"
        style={{ fontSize: '14px' }}></CommonHeaderText>
      <CommonHeaderText
        text={'OTP sent to you mobile number.'}
        style={{ fontSize: '14px' }}
      />
      <div style={{ font: 'normal normal normal 14px/21px Open Sans' }}>
        {values?.phone}{' '}
        <button
          onClick={() => {
            setOpenPopup(false);
          }}
          style={{
            color: '#028CF2',
            background: 'inherit',
            border: 'none',
            cursor: 'pointer',
          }}>
          [ Change ]
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30PX',
        }}>
        <OtpInput
          isInputNum={true}
          numInputs={6}
          value={otp}
          onChange={setOTP}
          separator={false}
          inputStyle={{
            marginRight: '10px',
            width: '40px',
            height: '50px',
            fontSize: '20px',
            border: '1px solid #767676',
          }}
        />
      </div>
      <div onClick={ResendOTP}>
        <CommonHeaderText
          text={<span className={styles.specialText}>Resend OTP</span>}
          variant="h5"
          style={{
            marginTop: '20px',
            color: '#171717',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            textAlign: 'center',
          }}
        />
      </div>
      {success != '' && <h5 className="success-text">{success}</h5>}

      {errorMsg != '' && <h5 className="error-text">{errorMsg}</h5>}

      {form_loading && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </div>
      )}
      <Button
        data-cy="verify-btn"
        value={otp}
        onClick={verifyBtn}
        className={styles.formSubmit}
        variant="contained">
        verify
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  properycontactedseenApiData: state.cotactedseenpropertyReducer,
});

const mapDispatchToProps = {
  contactedSeenPropertyApicall,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadOptForm);
